import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { noop } from 'lodash/fp';
import { PropTypes } from 'prop-types';
import { isActionKey } from '../../services/Keys';
import Checkbox from '../Checkbox';
import CheckboxContainer from '../CheckboxContainer';
import styles from './style.m.less';
export function PanelCheckboxButton(props) {
    const { checked, disabled, onClick, label, description, name } = props;
    const checkboxProps = {
        checked,
        label,
        name,
        onClick: noop,
        className: styles.checkbox,
        tabIndex: -1,
        compact: !description,
    };
    const handleClick = event => {
        if (!disabled) {
            return onClick(event);
        }
    };
    const handleKeyDown = event => {
        if (!disabled && isActionKey(event)) {
            event.preventDefault();
            return onClick(event);
        }
    };
    return (_jsx("div", { role: "checkbox", onClick: handleClick, onKeyDown: handleKeyDown, className: classNames('radio-btn-block-item', {
            'radio-btn-block-item-selected': checked,
            'radio-btn-block-item-disabled': disabled,
        }), tabIndex: disabled ? -1 : 0, "aria-checked": checked, children: _jsxs(CheckboxContainer, { children: [_jsx(Checkbox, Object.assign({}, checkboxProps)), description && _jsx("div", { className: "radio-btn-block-item-description", children: description })] }) }));
}
PanelCheckboxButton.propTypes = {
    checked: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.node,
    name: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};
