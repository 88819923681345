// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PanelCheckboxButton-checkbox-2qSjD,\n.PanelCheckboxButton-checkbox-2qSjD label {\n  pointer-events: none;\n}\n", ""]);
// Exports
exports.locals = {
	"checkbox": "PanelCheckboxButton-checkbox-2qSjD"
};
module.exports = exports;
