import { jsx as _jsx } from "react/jsx-runtime";
import { isBoolean } from 'lodash/fp';
import { PropTypes } from 'prop-types';
import { InputSubLabel } from '../InputSubLabel';
export default function InputError({ error }) {
    return (_jsx(InputSubLabel, { show: !!error && !isBoolean(error), isError: true, children: error }));
}
InputError.propTypes = {
    error: PropTypes.any,
};
