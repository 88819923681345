// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PanelRadioButtonGroup-item-15DJG {\n  border: 1px solid #e0dfde;\n  border-radius: 4px;\n  padding: 10px;\n  line-height: 18px;\n  cursor: pointer;\n  flex: 1 1 150px;\n}\n.PanelRadioButtonGroup-item_selected-dwx0I {\n  background: #0071d4;\n  color: #ffffff;\n}\n.PanelRadioButtonGroup-item_hide_radio-2vH69 .PanelRadioButtonGroup-input-OdgEH {\n  opacity: 0;\n  position: absolute;\n}\n.PanelRadioButtonGroup-item-15DJG:focus-within {\n  border-color: #0071d4;\n  box-shadow: 0 0 0 1px #0071d4;\n}\n.PanelRadioButtonGroup-item_disabled-1LGCQ {\n  opacity: 0.6;\n  pointer-events: none;\n  cursor: default;\n}\n.PanelRadioButtonGroup-item-15DJG:hover:not(.PanelRadioButtonGroup-item_selected-dwx0I) {\n  background: #f5f4f3;\n}\n.PanelRadioButtonGroup-item-15DJG.PanelRadioButtonGroup-single_child-WKZrJ .PanelRadioButtonGroup-label-2CGWP {\n  margin-bottom: 0;\n  font-weight: 400;\n  --fi-stroke: var(--fi-stroke-s);\n}\n.PanelRadioButtonGroup-label-2CGWP {\n  display: flex;\n  align-items: center;\n  cursor: inherit;\n  white-space: break-spaces;\n}\n.PanelRadioButtonGroup-label-2CGWP .section-icon-guided {\n  margin-right: 10px;\n}\n.PanelRadioButtonGroup-body-2EG6X {\n  margin-top: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"item": "PanelRadioButtonGroup-item-15DJG",
	"item_selected": "PanelRadioButtonGroup-item_selected-dwx0I",
	"item_hide_radio": "PanelRadioButtonGroup-item_hide_radio-2vH69",
	"input": "PanelRadioButtonGroup-input-OdgEH",
	"item_disabled": "PanelRadioButtonGroup-item_disabled-1LGCQ",
	"single_child": "PanelRadioButtonGroup-single_child-WKZrJ",
	"label": "PanelRadioButtonGroup-label-2CGWP",
	"body": "PanelRadioButtonGroup-body-2EG6X"
};
module.exports = exports;
