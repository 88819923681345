// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".InputButton-inputButton-O10oN {\n  position: absolute;\n  margin: 0;\n}\n.InputButton-default-1C2BH {\n  height: 28px;\n  top: 6px;\n  right: 6px;\n  padding: 4px;\n  padding-top: 5px;\n}\n.InputButton-small-2NIom {\n  height: 22px;\n  top: 3px;\n  right: 3px;\n  padding: 2px;\n}\n.InputButton-title-2ZibG {\n  height: 29px;\n  top: 3px;\n  right: 3px;\n  padding: 2px;\n}\n.InputButton-inputButtonIcon-HWLOw {\n  position: absolute;\n  margin: 0;\n  color: #7a7a79;\n  border-radius: 4px;\n  text-align: center;\n}\n.InputButton-inputButtonIcon-HWLOw:hover,\n.InputButton-inputButtonIcon-HWLOw:focus {\n  color: #1a1a1a;\n  background-color: #edeceb;\n}\n.InputButton-inputButtonIcon-HWLOw:focus {\n  box-shadow: 0 0 0 2px #0071d4;\n  outline: none;\n}\n", ""]);
// Exports
exports.locals = {
	"inputButton": "InputButton-inputButton-O10oN",
	"default": "InputButton-default-1C2BH",
	"small": "InputButton-small-2NIom",
	"title": "InputButton-title-2ZibG",
	"inputButtonIcon": "InputButton-inputButtonIcon-HWLOw"
};
module.exports = exports;
