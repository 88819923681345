import { jsx as _jsx } from "react/jsx-runtime";
import moment from 'moment';
import PropTypes from 'prop-types';
import * as React from 'react';
import Picker from './Picker';
/**
 * Displays a date picker
 *
 * @prop {function} onChange Triggered when a new date (or no date) is selected
 * @prop {string | undefined} date Currently selected date. May only be `undefined` if `isClearable=true`
 * @prop {boolean?} isClearable If true, `undefined` is a valid date and no date can be selected without error message. Defaults to `false`
 * @prop {string?} kind Visual kind. Defaults to `DEFAULT`
 * @prop {Array<string>?} highlightedDates Dates that will be highlighted with a dot
 * @prop {DateRange?} validRange Dates outside of the validRange will be disabled
 * @prop {boolean?} disabled If true, the component is disabled. Defaults to `false`
 * @prop {boolean?} readOnly If true, the component is readonly. Defaults to `false`
 * @prop {boolean?} hasError If true, the error styles are applied. Defaults to `false`
 * @prop {showIcon?} showIcon If true, the arrow on the right is displayed. Defaults to `true`
 * @prop {string?} placeholderText Text that is displayed when the input field is empty. Defaults to `''`
 * @prop {string?} id Sets the id of the input field
 * @prop {function?} formatPrefix Callback that returns the string that should be printed before the input element
 */
class Wrapper extends React.Component {
    constructor() {
        super(...arguments);
        this.handleChange = (newDate) => {
            if (this.props.isClearable) {
                this.props.onChange(newDate && newDate.format(moment.HTML5_FMT.DATE));
            }
            else if (!!newDate) {
                this.props.onChange(newDate.format(moment.HTML5_FMT.DATE));
            }
        };
        this.formatPrefix = (date) => {
            if (!this.props.formatPrefix) {
                return;
            }
            if (this.props.isClearable) {
                return this.props.formatPrefix(date && date.format(moment.HTML5_FMT.DATE));
            }
            else if (!!date) {
                return this.props.formatPrefix(date.format(moment.HTML5_FMT.DATE));
            }
            else {
                return undefined;
            }
        };
    }
    render() {
        const { date, onFocus, isClearable, kind, highlightedDates, validRange, disabled, readOnly, hasError, showIcon, placeholderText, id, isSmall, } = this.props;
        return (_jsx(Picker, { onChange: this.handleChange, date: date ? moment(date, moment.HTML5_FMT.DATE) : undefined, onFocus: onFocus, isClearable: isClearable, kind: kind, highlightedDates: highlightedDates, validRange: validRange, disabled: disabled, readOnly: readOnly, hasError: hasError, showIcon: showIcon, placeholderText: placeholderText, id: id, formatPrefix: this.formatPrefix, isSmall: isSmall }));
    }
}
Wrapper.propTypes = {
    onChange: PropTypes.func.isRequired,
    date: PropTypes.string,
    isClearable: PropTypes.bool,
    kind: PropTypes.oneOf(['DEFAULT', 'TITLE']),
    highlightedDates: PropTypes.arrayOf(PropTypes.string),
    validRange: PropTypes.shape({ from: PropTypes.string, to: PropTypes.string }),
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    hasError: PropTypes.bool,
    showIcon: PropTypes.bool,
    placeholderText: PropTypes.string,
    id: PropTypes.string,
    formatPrefix: PropTypes.func,
    isSmall: PropTypes.bool,
};
Wrapper.defaultProps = {
    onFocus: () => undefined,
    isClearable: false,
    kind: 'DEFAULT',
    highlightedDates: [],
    validRange: {},
    disabled: false,
    readOnly: false,
    hasError: false,
    showIcon: true,
    placeholderText: '',
};
export default Wrapper;
