// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoadingBar-container-2V5Pu {\n  margin: 20px 0;\n}\n.LoadingBar-bar-YpoPr {\n  height: 10px;\n  width: 100%;\n  position: relative;\n  overflow: hidden;\n  background-color: #f5f4f3;\n}\n.LoadingBar-bar-YpoPr:before {\n  display: block;\n  position: absolute;\n  content: '';\n  left: -250px;\n  width: 30%;\n  max-width: 250px;\n  height: 10px;\n  background-color: #e0dfde;\n  animation: LoadingBar-loading-W1Gqe 1.5s ease infinite;\n}\n@keyframes LoadingBar-loading-W1Gqe {\n  from {\n    left: -250px;\n  }\n  to {\n    left: 110%;\n  }\n}\n.LoadingBar-label-8bO-k {\n  color: #7a7a79;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "LoadingBar-container-2V5Pu",
	"bar": "LoadingBar-bar-YpoPr",
	"loading": "LoadingBar-loading-W1Gqe",
	"label": "LoadingBar-label-8bO-k"
};
module.exports = exports;
