import { jsx as _jsx } from "react/jsx-runtime";
import { render } from 'react-dom';
export default ({ Component, id }) => {
    const node = document.getElementById(id);
    if (!node) {
        return;
    }
    node.setAttribute('ng-non-bindable', true);
    render(_jsx(Component, { mountNode: node }), node);
};
