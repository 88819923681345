import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PropTypes } from 'prop-types';
import renderTintColor from '../TintColor';
import styles from './style.m.less';
const NO_STYLE = {};
LoadingIndicator.propTypes = {
    small: PropTypes.bool,
    useTint: PropTypes.bool,
};
const BIG_SIZE = '40px';
export default function LoadingIndicator({ useTint = false, small = true }) {
    const sizeStyle = small ? {} : { height: BIG_SIZE, width: BIG_SIZE };
    const style = useTint ? { backgroundColor: renderTintColor() } : NO_STYLE;
    return (_jsx("div", { className: styles.loading_indicator, children: _jsxs("div", { className: styles.inner, children: [_jsx("div", { className: styles.ball_1, style: Object.assign(Object.assign({}, style), sizeStyle) }), _jsx("div", { className: styles.ball_2, style: Object.assign(Object.assign({}, style), sizeStyle) })] }) }));
}
