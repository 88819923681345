// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PopupAlert-outerContainer-1O1wo {\n  z-index: 550;\n  position: -webkit-sticky;\n  position: sticky;\n  top: calc(var(--offlineWarningOffset) - var(--headerBroadcastOffset));\n  right: 0;\n  left: 0;\n  transform: translateY(calc(50px + var(--headerBroadcastOffset)));\n}\n.ReactModal__Body--open .PopupAlert-outerContainer-1O1wo,\n.overlay-open .PopupAlert-outerContainer-1O1wo,\n.FullScreenRoute--open .PopupAlert-outerContainer-1O1wo,\n.iframeEmbedBody .PopupAlert-outerContainer-1O1wo {\n  position: fixed;\n  z-index: 901;\n  top: 0;\n  transform: none;\n}\n.ReactModal__Body--open .PopupAlert-outerContainer-1O1wo .PopupAlert-popup_alert-bdbkB,\n.overlay-open .PopupAlert-outerContainer-1O1wo .PopupAlert-popup_alert-bdbkB,\n.FullScreenRoute--open .PopupAlert-outerContainer-1O1wo .PopupAlert-popup_alert-bdbkB,\n.iframeEmbedBody .PopupAlert-outerContainer-1O1wo .PopupAlert-popup_alert-bdbkB {\n  top: 10px;\n  right: 10px;\n}\n.ReactModal__Body--open .PopupAlert-outerContainer-1O1wo .PopupAlert-innerContainer-ceOZN,\n.overlay-open .PopupAlert-outerContainer-1O1wo .PopupAlert-innerContainer-ceOZN,\n.FullScreenRoute--open .PopupAlert-outerContainer-1O1wo .PopupAlert-innerContainer-ceOZN,\n.iframeEmbedBody .PopupAlert-outerContainer-1O1wo .PopupAlert-innerContainer-ceOZN {\n  max-width: initial;\n}\n.PopupAlert-innerContainer-ceOZN {\n  position: relative;\n  max-width: 1400px;\n  margin: 0 auto;\n}\n.PopupAlert-popup_alert-bdbkB {\n  position: absolute;\n  width: 410px;\n  max-width: calc(100vw - (20px * 2));\n  top: 20px;\n  right: 20px;\n  border-radius: 4px;\n  background-color: #ffffff;\n  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);\n}\n.PopupAlert-dismiss-2ZYi5 {\n  position: absolute;\n  top: 15px;\n  right: 15px;\n  line-height: 1;\n  font-size: 18px;\n  color: #7a7a79;\n  --fi-size: 20px;\n}\n.PopupAlert-dismiss-2ZYi5:hover {\n  color: #4d4d4c;\n}\n", ""]);
// Exports
exports.locals = {
	"outerContainer": "PopupAlert-outerContainer-1O1wo",
	"popup_alert": "PopupAlert-popup_alert-bdbkB",
	"innerContainer": "PopupAlert-innerContainer-ceOZN",
	"dismiss": "PopupAlert-dismiss-2ZYi5"
};
module.exports = exports;
