// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Tabs-container-fkaK8 {\n  margin-top: 20px;\n  display: flex;\n  flex-direction: column;\n}\n.Tabs-tabOptions-2g80G {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  text-align: right;\n  padding-right: 1px;\n}\n.Tabs-tabList-2vgRY {\n  border-bottom: 1px solid #e0dfde;\n  margin: 0;\n  padding: 0;\n  display: flex;\n  flex: 1 0 auto;\n  overflow-x: auto;\n  overflow-y: hidden;\n}\n.Tabs-tab-1jAuJ {\n  border-bottom: 2px solid transparent;\n  list-style: none;\n  margin: 0;\n  text-align: center;\n  padding: 10px;\n  white-space: nowrap;\n  color: #4d4d4c;\n}\n.Tabs-tab-1jAuJ:not(.Tabs-disabled-3aqnp, .Tabs-selected-10gjN):hover,\n.Tabs-tab-1jAuJ:not(.Tabs-disabled-3aqnp, .Tabs-selected-10gjN):focus {\n  color: #1a1a1a;\n  border-color: #c7c6c4;\n  cursor: pointer;\n}\n.Tabs-tab-1jAuJ:not(.Tabs-disabled-3aqnp, .Tabs-selected-10gjN):focus {\n  background-color: #e0dfde;\n  outline: none;\n}\n.Tabs-tab-1jAuJ.Tabs-disabled-3aqnp {\n  color: #a3a2a1;\n}\n.Tabs-tab-1jAuJ > a {\n  text-decoration: none;\n}\n.Tabs-compact-CSJ3Y {\n  margin-top: 0;\n}\n.Tabs-compact-CSJ3Y .Tabs-tabList-2vgRY .Tabs-tab-1jAuJ {\n  flex: 1 0 auto;\n  padding: 5px 10px;\n}\n.Tabs-selected-10gjN {\n  font-weight: 500;\n  border-color: var(--companyColor);\n  --fi-stroke: var(--fi-stroke-m);\n}\n.Tabs-selected-10gjN:hover {\n  cursor: default;\n  background: transparent;\n}\n.Tabs-tabPanel-K9sYC > .Tabs-cards_section-32-9d {\n  margin-top: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "Tabs-container-fkaK8",
	"tabOptions": "Tabs-tabOptions-2g80G",
	"tabList": "Tabs-tabList-2vgRY",
	"tab": "Tabs-tab-1jAuJ",
	"disabled": "Tabs-disabled-3aqnp",
	"selected": "Tabs-selected-10gjN",
	"compact": "Tabs-compact-CSJ3Y",
	"tabPanel": "Tabs-tabPanel-K9sYC",
	"cards_section": "Tabs-cards_section-32-9d"
};
module.exports = exports;
