import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { map } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { trackEvent } from '../../../react/services/Tracking';
import styles from './style.m.less';
let nameCounter = 0;
const getName = (name = '') => `horizontalradio-${name && name.length ? name : nameCounter++}`;
function HorizontalRadio({ value, options, onChange, name, className, small = true, eventTracking, onClear }) {
    const inputName = getName(name);
    return (_jsx("div", { className: classNames(className, 'horizontal-radio', { small, [styles.clearable]: !!onClear }), children: map(option => {
            const id = `${inputName}-${option.value}`;
            return (_jsxs(Fragment, { children: [_jsx("input", { id: id, name: inputName, disabled: option.disabled, type: "radio", checked: value === option.value, onChange: () => {
                            if (eventTracking) {
                                trackEvent(Object.assign(Object.assign({}, eventTracking), { label: option.trackingLabel }));
                            }
                            if (option.value !== value) {
                                onChange(option.value);
                            }
                        }, onClick: onClear && option.value === value ? onClear : undefined }), _jsx("label", { htmlFor: id, title: option.title, className: classNames(styles.label, {
                            [styles.smallLabel]: small,
                            [styles.regularLabel]: !small,
                        }), children: option.label })] }, option.value));
        }, options) }));
}
HorizontalRadio.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        trackingLabel: PropTypes.string,
        disabled: PropTypes.bool,
    })),
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    className: PropTypes.string,
    small: PropTypes.bool,
    eventTracking: PropTypes.shape({
        category: PropTypes.string.isRequired,
        action: PropTypes.string.isRequired,
    }),
    onClear: PropTypes.func,
};
export default HorizontalRadio;
