// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HorizontalRadio-label-2hJMF {\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n}\n.HorizontalRadio-regularLabel-2fBy0 {\n  font-size: 15px;\n  --fi-size: 18px;\n}\n.HorizontalRadio-smallLabel-3TCLt {\n  font-size: 13px;\n  --fi-size: 14px;\n}\n.HorizontalRadio-clearable-1YOT7 label,\n.HorizontalRadio-clearable-1YOT7 input {\n  cursor: pointer;\n}\n.HorizontalRadio-clearable-1YOT7 label:checked + label:hover,\n.HorizontalRadio-clearable-1YOT7 input:checked + label:hover {\n  background-color: #007fee;\n}\n", ""]);
// Exports
exports.locals = {
	"label": "HorizontalRadio-label-2hJMF",
	"regularLabel": "HorizontalRadio-regularLabel-2fBy0",
	"smallLabel": "HorizontalRadio-smallLabel-3TCLt",
	"clearable": "HorizontalRadio-clearable-1YOT7"
};
module.exports = exports;
