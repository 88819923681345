export const mkEmptyUserSelectionFilterState = () => ({
    type: 'FILTER',
    propertyFilters: {
        location: [],
        role: [],
        department: [],
        team: [],
        employmentType: [],
        hiringDate: null,
        hiringDateRelative: null,
    },
    hierarchyFilters: {
        reportsOf: [],
        level: null,
    },
    idFilters: {
        excluded: [],
        included: [],
    },
});
export const mkEmptyUserSelectionStaticState = () => ({
    type: 'STATIC',
    userIds: [],
});
