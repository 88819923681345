// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AriaHelper-offScreen-3Fw2o {\n  border: none;\n  clip: rect(0 0 0 0);\n  height: 1px;\n  margin: -1px;\n  overflow: hidden;\n  padding: 0;\n  position: absolute;\n  width: 1px;\n}\n", ""]);
// Exports
exports.locals = {
	"offScreen": "AriaHelper-offScreen-3Fw2o"
};
module.exports = exports;
