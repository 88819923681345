// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes Spinner-Spinner-spin-1coPd {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(359deg);\n  }\n}\n.Spinner-container-2jy_M svg {\n  fill: #7a7a79;\n  animation: Spinner-Spinner-spin-1coPd 800ms infinite cubic-bezier(0.7, 0.25, 0.25, 0.7);\n}\n", ""]);
// Exports
exports.locals = {
	"container": "Spinner-container-2jy_M",
	"Spinner-spin": "Spinner-Spinner-spin-1coPd"
};
module.exports = exports;
