// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Breadcrumbs-container-6CkFp {\n  list-style-type: none;\n  padding: 0;\n  margin-top: 0;\n  margin-bottom: 10px;\n  font-size: 13px;\n  color: #a3a2a1;\n  --fi-size: 14px;\n}\n.Breadcrumbs-container-6CkFp li {\n  display: inline-block;\n  cursor: default;\n}\n.Breadcrumbs-container-6CkFp li [data-tooltip] {\n  display: inline;\n}\n.Breadcrumbs-chevron-hgndJ {\n  display: inline-block;\n  margin: 0 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "Breadcrumbs-container-6CkFp no-print",
	"chevron": "Breadcrumbs-chevron-hgndJ"
};
module.exports = exports;
