// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EntityPreview-preview-3iBiU {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  min-width: 0;\n}\n.EntityPreview-title-m_3bi,\n.EntityPreview-subtitle-1JIgM {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  line-height: 18px;\n  margin: 0;\n}\n.EntityPreview-title-m_3bi {\n  font-size: 15px;\n  font-weight: 400;\n  margin: 4px 0;\n  --fi-size: 18px;\n  --fi-stroke: var(--fi-stroke-s);\n}\n.EntityPreview-subtitle-1JIgM {\n  color: #a3a2a1;\n  font-size: 13px;\n  --fi-size: 14px;\n}\n", ""]);
// Exports
exports.locals = {
	"preview": "EntityPreview-preview-3iBiU",
	"title": "EntityPreview-title-m_3bi",
	"subtitle": "EntityPreview-subtitle-1JIgM"
};
module.exports = exports;
