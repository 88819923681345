// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PopupAlertContent-outerFlexRow-3K9NE {\n  display: flex;\n  justify-content: space-between;\n  overflow: hidden;\n}\n.PopupAlertContent-icon-2IQxQ {\n  min-width: 60px;\n  min-height: 60px;\n  margin-right: 10px;\n}\n.PopupAlertContent-content-2DWDM {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  min-width: 0;\n}\n.PopupAlertContent-title-1-iCg {\n  margin: 0;\n  line-height: 1;\n  font-size: 18px;\n  --fi-size: 20px;\n}\n.PopupAlertContent-info-2_5uR {\n  display: flex;\n}\n.PopupAlertContent-button-3Lwiu {\n  flex-shrink: 0;\n  margin: 0 0 0 5px;\n  align-self: flex-end;\n}\n", ""]);
// Exports
exports.locals = {
	"outerFlexRow": "PopupAlertContent-outerFlexRow-3K9NE",
	"icon": "PopupAlertContent-icon-2IQxQ",
	"content": "PopupAlertContent-content-2DWDM",
	"title": "PopupAlertContent-title-1-iCg",
	"info": "PopupAlertContent-info-2_5uR",
	"button": "PopupAlertContent-button-3Lwiu button small"
};
module.exports = exports;
