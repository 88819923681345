import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import WithModal from './WithModal';
/**
 * Decorator that wraps a component in a WithModal component.
 *
 * Usage:
 *
 * ```
 * const MyComponentWithModal = withModal(MyComponent)
 *
 * <MyComponentWithModal
 *      modalProps={{body: ModalBodyComponent, ...otherModalProps}}
 *      requireExplicitClose={boolean}
 *      {...propsForMyComponent}
 * />
 * ```
 *
 * It passes an `onClick` handler to MyComponent that opens the modal.
 * It doesn't forward any props to the Modalbody except for a `closeModal` callback
 */
export default function withModal(WrappedComponent) {
    return (allProps) => {
        const _a = allProps.modalProps, { body } = _a, modalProps = __rest(_a, ["body"]), { requireExplicitClose } = allProps, props = __rest(allProps, ["modalProps", "requireExplicitClose"]);
        // @ts-ignore Due to a bug in Typescript, destructuring doesn't work super well right now:
        // - https://github.com/Microsoft/TypeScript/issues/28938
        // - https://github.com/Microsoft/TypeScript/issues/29175
        const wrappedProps = props;
        return (_jsx(WithModal, Object.assign({ noCloseIcon: false }, modalProps, { requireExplicitClose: requireExplicitClose, renderModalContent: body, children: ({ openModal }) => _jsx(WrappedComponent, Object.assign({}, wrappedProps, { onClick: openModal })) })));
    };
}
export { WithModal };
