import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { IconCheck, IconX } from 'featherico';
import * as React from 'react';
import ReactToggle from 'react-toggle';
import colours from '../../../colours';
import styles from './style.m.less';
export default function Toggle({ checked, onChange, disabled = false, label = undefined, subLabel = undefined, id, }) {
    return (_jsxs(React.Fragment, { children: [_jsxs("label", { css: {
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                    height: 'fit-content',
                }, children: [_jsx(ReactToggle, { className: styles.reactToggle, checked: checked, onChange: onChange, disabled: disabled, icons: {
                            checked: _jsx(IconCheck, { css: { marginTop: '0 !important' } }),
                            unchecked: _jsx(IconX, { css: { marginTop: '0 !important' } }),
                        }, id: id }), label && _jsx("div", { css: { marginLeft: 5, color: disabled ? colours.SIGray2 : 'inherit' }, children: label })] }), subLabel && _jsx("div", { className: styles.subLabel, children: subLabel })] }));
}
