import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconChevronRight } from 'featherico';
import { map, truncate } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { BASE } from '../../../routes';
import { preventJavascriptUrls } from '../../../services/security';
import Tooltip from '../Tooltip';
import styles from './style.m.less';
const propTypes = {
    path: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string,
        prependBaseUrl: PropTypes.bool,
    })).isRequired,
};
const TRUNCATE_LENGTH = 45;
const withTooltip = (element, tooltip) => (_jsx(Tooltip, { tooltip: tooltip, position: "bottom", children: element }));
export default function Breadcrumbs(props) {
    return (_jsx("ul", { className: styles.container, children: map(item => (_createElement(Breadcrumb, Object.assign({}, item, { key: item.label }))), props.path) }));
}
Breadcrumbs.propTypes = propTypes;
function Breadcrumb({ prependBaseUrl, url, label }) {
    const isLong = label.length > TRUNCATE_LENGTH;
    const displayLabel = isLong ? truncate({ length: TRUNCATE_LENGTH }, label) : label;
    const contentProps = {
        prependBaseUrl,
        url,
        displayLabel,
    };
    return (_jsx("li", { children: isLong ? (withTooltip(_jsx(BreadcrumbContent, Object.assign({}, contentProps)), label)) : (_jsx(BreadcrumbContent, Object.assign({}, contentProps))) }));
}
function BreadcrumbContent({ prependBaseUrl, url, displayLabel }) {
    return url ? (_jsxs(Fragment, { children: [_jsx("a", { href: preventJavascriptUrls(`${prependBaseUrl ? BASE : ''}${url}`), children: displayLabel }), _jsx(IconChevronRight, { className: styles.chevron })] })) : (_jsx("span", { children: displayLabel }));
}
