export default {
    "SIBlue1": "#0f4e96",
    "SIBlue2": "#0071d4",
    "SIBlue3": "#66b6ff",
    "SIBlue4": "#99cfff",
    "SIBlue5": "#cce7ff",
    "SIBlue6": "#e6f3ff",
    "SIBlue7": "#f2f9ff",
    "SIBlueMain": "#0071d4",
    "SIRedLight": "#fff3f3",
    "SIRed": "#f23037",
    "SIOrangeLight": "#fff7d9",
    "SISalmonLight": "#fff0f0",
    "SISalmon": "#ff9797",
    "SISalmonDark": "#e75c5c",
    "SIGreenLight": "#eafaf0",
    "SILightGreen": "#aeef88",
    "SIGreen": "#2ecd72",
    "SIGreenDark": "#08994e",
    "SITeal": "#2eccc4",
    "SITealLight": "#eaf9f9",
    "SIYellowLightest": "#fff8d9",
    "SIYellowLight": "#ffe57f",
    "SIYellowMid": "#ffd633",
    "SIYellow": "#ffcc00",
    "SIYellowOrange": "#fcac53",
    "SIYellowOrangeMid": "#ffa200",
    "SIYellowOrangeDark": "#d17918",
    "SIPurple": "#9ea5cc",
    "SIDesertRed": "#e06839",
    "SIPastelBlue": "#a0c0da",
    "SIBlack": "#1a1a1a",
    "SIGray1": "#4d4d4c",
    "SIGray2": "#7a7a79",
    "SIGray3": "#a3a2a1",
    "SIGray4": "#c7c6c5",
    "SIGray5": "#e0dfde",
    "SIGray6": "#edeceb",
    "SIGray7": "#f5f4f3",
    "SIGray8": "#faf9f7",
    "SIWhite": "#ffffff",
    "SIDataBadredLightest": "#fff3f3",
    "SIDataBadredLight": "#ffa6a6",
    "SIDataBadred": "#f23037",
    "SIDataBadredDark": "#cc141b",
    "SIDataGoodgreenLightest": "#d5f5e3",
    "SIDataGoodgreenLight": "#96e6b8",
    "SIDataGoodgreen": "#2ecd72",
    "SIDataGoodgreenDark": "#187a48",
    "SIDataBlueLight": "#99cfff",
    "SIDataBlue": "#66b6ff",
    "SIDataBlueDark": "#0f4e96",
    "SIDataOrangeLight": "#fdd1ab",
    "SIDataOrange": "#fca558",
    "SIDataOrangeDark": "#c45327",
    "SIDataTurquoiseLight": "#96e5df",
    "SIDataTurquoise": "#2dccc0",
    "SIDataTurquoiseDark": "#00857a",
    "SIDataSalmonLight": "#ffa6a6",
    "SIDataSalmon": "#e75c5c",
    "SIDataSalmonDark": "#c23a3a",
    "SIDataMatchaLight": "#d8f7c6",
    "SIDataMatcha": "#aeef88",
    "SIDataMatchaDark": "#56803d",
    "SIDataPurpleLight": "#cac1eb",
    "SIDataPurple": "#9584d8",
    "SIDataPurpleDark": "#5c4d99",
    "SIDataYellowLight": "#ffe57f",
    "SIDataYellow": "#ffcc00",
    "SIDataYellowDark": "#a36810"
}
