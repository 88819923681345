// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SaveButton-saved-1hP4D[disabled] {\n  color: #2ecd72;\n}\n", ""]);
// Exports
exports.locals = {
	"saved": "SaveButton-saved-1hP4D"
};
module.exports = exports;
