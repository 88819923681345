// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".icons-objectiveIcon-2zKSP {\n  width: 60px;\n  height: 60px;\n  border-radius: 4px;\n}\n.icons-surveyIconWrapper-iEruJ {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 60px;\n  height: 60px;\n  border-radius: 4px;\n  background: #f5f4f3;\n  font-size: 26px;\n  --fi-size: 28px;\n}\n", ""]);
// Exports
exports.locals = {
	"objectiveIcon": "icons-objectiveIcon-2zKSP",
	"surveyIconWrapper": "icons-surveyIconWrapper-iEruJ"
};
module.exports = exports;
