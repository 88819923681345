import moment from 'moment';
import { groupManagersAndNonManagers, indirectReportsOf } from '../../services/UserHierarchy';
export const basePropertyFilters = ['employmentType', 'department', 'location', 'team', 'role'];
export function getUsersForSelection(allUsers, selection) {
    switch (selection.type) {
        case 'FILTER':
            return getUsersForSelectionFilter(allUsers, selection);
        case 'STATIC':
            return getUsersForSelectionStatic(allUsers, selection);
    }
}
function getUsersForSelectionFilter(allUsers, { propertyFilters, hierarchyFilters, idFilters }) {
    const getPropertyFilter = getPropertyFilters(propertyFilters);
    const filters = [
        getPropertyFilter('location'),
        getPropertyFilter('role'),
        getPropertyFilter('department'),
        getPropertyFilter('team'),
        getPropertyFilter('employmentType'),
        getHiringDateFilter(propertyFilters.hiringDate),
        getHiringDateRelativeFilter(propertyFilters.hiringDateRelative),
        ...getHierarchyFilters(allUsers, hierarchyFilters),
    ];
    const includedIds = new Set(idFilters.included);
    const excludedIds = new Set(idFilters.excluded);
    return allUsers.filter(user => includedIds.has(user.id) || (!excludedIds.has(user.id) && filters.every(filterFn => filterFn(user))));
}
function getPropertyFilters(propertyFilters) {
    return (key) => {
        const filterSet = new Set(propertyFilters[key]);
        if (!filterSet.size) {
            return () => true;
        }
        return user => {
            const prop = user[key];
            return prop !== null && filterSet.has(prop);
        };
    };
}
function getHiringDateFilter(hiringDate) {
    if (!hiringDate) {
        return () => true;
    }
    return user => !!user.hiringCalendarDate &&
        (!!hiringDate.from ? user.hiringCalendarDate >= hiringDate.from : true) &&
        (!!hiringDate.to ? user.hiringCalendarDate <= hiringDate.to : true);
}
function getHiringDateRelativeFilter(hiringDateRelative) {
    if (!hiringDateRelative) {
        return () => true;
    }
    return user => {
        if (!user.hiringCalendarDate) {
            return false;
        }
        const date = moment().subtract(hiringDateRelative.count, hiringDateRelative.unit).format(moment.HTML5_FMT.DATE);
        return hiringDateRelative.beforeOrAfter === 'after'
            ? date <= user.hiringCalendarDate
            : date > user.hiringCalendarDate;
    };
}
function getHierarchyFilters(allUsers, hierarchyFilters) {
    const filters = [];
    if (hierarchyFilters.level) {
        const { managers, nonManagers } = groupManagersAndNonManagers(allUsers);
        const managerIds = new Set(managers.map(user => user.id));
        const nonManagerIds = new Set(nonManagers.map(user => user.id));
        filters.push({
            MANAGER: (user) => managerIds.has(user.id),
            NON_MANAGER: (user) => nonManagerIds.has(user.id),
        }[hierarchyFilters.level]);
    }
    if (hierarchyFilters.reportsOf.length) {
        const managerIds = new Set(hierarchyFilters.reportsOf);
        const managers = allUsers.filter(user => managerIds.has(user.id));
        const reportIds = new Set(indirectReportsOf(allUsers, managers).map(user => user.id));
        filters.push(user => reportIds.has(user.id));
    }
    return filters;
}
function getUsersForSelectionStatic(allUsers, selection) {
    const userIds = new Set(selection.userIds);
    return allUsers.filter(user => userIds.has(user.id));
}
export function getActiveFilterCount(filters) {
    return [
        basePropertyFilters.reduce((count, filter) => count + (filters.propertyFilters[filter] === null || !filters.propertyFilters[filter].length ? 0 : 1), 0),
        !!filters.idFilters.excluded.length || !!filters.idFilters.excluded.length ? 1 : 0,
        !!filters.hierarchyFilters.reportsOf.length || filters.hierarchyFilters.level !== null ? 1 : 0,
        !!filters.propertyFilters.hiringDate ? 1 : 0,
        !!filters.propertyFilters.hiringDateRelative ? 1 : 0,
    ].reduce((a, b) => a + b, 0);
}
