import { useCallback, useEffect, useState } from 'react';
import { getFullKey, getObjectFromStorage, replaceObjectInStorage } from '../services/LocalStorage';
/**
 * This can be used instead of useState to store information in localStorage
 *
 * @param storageKey This identifies the storage object within SI. It's prefixed properly behind the scenes, so pick a short name
 * @param init If there is nothing stored already, use this as the default value (similar to how useState works)
 */
export default function useLocalStorage(storageKey, init) {
    const [state, setState] = useState(() => getObjectFromStorage(storageKey, init));
    const replace = useCallback((o) => {
        replaceObjectInStorage(storageKey, o);
        setState(getObjectFromStorage(storageKey, init));
    }, [init, storageKey]);
    useEffect(() => {
        const handleStorageEvent = (event) => {
            if (event.key === getFullKey(storageKey)) {
                setState(getObjectFromStorage(storageKey, init));
            }
        };
        window.addEventListener('storage', handleStorageEvent);
        return () => window.removeEventListener('storage', handleStorageEvent);
    }, [init, storageKey]);
    return [state, replace];
}
