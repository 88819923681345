import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import * as React from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';
import InputError from '../Input/InputError';
import styles from './style.m.less';
function TextareaAutosize(_a, ref) {
    var { error, className } = _a, otherProps = __rest(_a, ["error", "className"]);
    return (_jsxs(_Fragment, { children: [_jsx(ReactTextareaAutosize, Object.assign({}, otherProps, { className: classNames([
                    styles.textareaAutosize,
                    className,
                    {
                        [styles.invalid]: !!error,
                    },
                ]), ref: ref })), _jsx(InputError, { error: error })] }));
}
export default React.forwardRef(TextareaAutosize);
